export const initialRows = [
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP3_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP3_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_ERA_RU v20",
    property: "Allows self controlled",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO45x_983_HCP5_44/24_ERA_RU v16",
    property: "APN",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_48/24_ERA_RU v6",
    property: "Associated parameters",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO54x_E4_HCP5_50/24_ERA_RU v12",
    property: "Can be configured",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_CN v115",
    property: "Can be personalised",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_US/PR v100",
    property: "Channels",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_NAR_CA v45",
    property: "Description",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP v73",
    property: "Consent Documend IDs",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
  {
    service: "mbboauth_v1",
    sop: "PO426_MacanNF_HCP5_49/23_PAP_JP v42",
    property: "Is master-id",
    sopWork: "no",
    sopDemo: "no",
    sopApproval: "no",
    sopLive: "no",
  },
];
